import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';

import { AuthGuard } from './appservices/auth.guard';
import { RoleGuardGuard } from './appservices/role-guard.guard';
import { SubroleGuardGuard } from './appservices/subrole-guard.guard';
import { UserAuthService } from './appservices/user-auth.service';

@NgModule({
  declarations: [AppComponent, routingComponents],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    LoadingBarModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      closeButton: true,
      progressBar: false,
    }),
    DataTablesModule,
  ],
  providers: [UserAuthService, AuthGuard, RoleGuardGuard, SubroleGuardGuard],
  bootstrap: [AppComponent],
})
export class AppModule {}
