import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserAuthService } from '../../appservices/user-auth.service';

@Component({
  selector: 'app-app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.css'],
})
export class AppHeaderComponent implements OnInit {
  constructor(
    public userAuthService: UserAuthService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  userLogout() {
    this.userAuthService.logout();
    this.router.navigate(['/']);
  }
}
