import { Component, OnInit } from '@angular/core';

declare let $: any;
@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.css'],
})
export class AppLayoutComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  public scrollToTop(event) {
    event.stopPropagation();
    window.scrollTo({ top: 0, behavior: 'smooth' });
    $('html, body').animate({ scrollTop: 0 }, 1000, 'easeInOutExpo');
  }
}
