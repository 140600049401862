import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserAuthService {
  constructor(private http: HttpClient) {}

  authToken = '';
  userData = '';
  userDataLoc = {
    name: '',
    email: '',
    id: ''
  };
  public apiUrl = environment.baseUrl;

  public storeUserData(token, authdata) {
    localStorage.setItem('hks_admin_token', token);
    localStorage.setItem('hks_admin_data', JSON.stringify(authdata));
    (this.authToken = token), (this.userData = authdata);
  }

  public getUserName() {
    if (
      !localStorage.getItem('hks_admin_data') ||
      localStorage.getItem('hks_admin_data') == 'undefined'
    ) {
      return false;
    }
    this.userDataLoc = JSON.parse(localStorage.getItem('hks_admin_data'));
    if (this.userDataLoc.name) {
      return this.userDataLoc.name;
    }
  }

  public isAuthenticated(): boolean {
    if (
      !localStorage.getItem('hks_admin_data') ||
      localStorage.getItem('hks_admin_data') == 'undefined'
    ) {
      return false;
    }
    this.userDataLoc = JSON.parse(localStorage.getItem('hks_admin_data'));
    if (
      this.userDataLoc &&
      localStorage.getItem('hks_admin_token') &&
      this.userDataLoc.name &&
      this.userDataLoc.email
    ) {
      return true;
    } else {
      return false;
    }
  }

  public checkLoggin() {
    if (
      !localStorage.getItem('hks_admin_data') ||
      localStorage.getItem('hks_admin_data') == 'undefined'
    ) {
      return false;
    }
    this.userDataLoc = JSON.parse(localStorage.getItem('hks_admin_data'));
    if (
      this.userDataLoc &&
      localStorage.getItem('hks_admin_token') &&
      this.userDataLoc.name &&
      this.userDataLoc.email
    ) {
      return true;
    } else {
      return false;
    }
  }

  public logout() {
    this.authToken = null;
    this.userData = null;
    localStorage.removeItem('hks_admin_token');
    localStorage.removeItem('hks_admin_data');
  }

  /*
   *----------------------------------------------------------------
   */
  public adminLogin(data): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Channel: 'WEB_ADMIN'
    });
    return this.http
      .post<any>(`${this.apiUrl}super-admin/admin-login`, data, { headers })
      .pipe(catchError(this.errorHandler));
  }

  /*
   *----------------------------------------------------------------
   */
  getEmpProfile(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Channel: 'WEB_ADMIN',
      Authorization: localStorage.getItem('hks_admin_token')
    });
    return this.http
      .get<any>(`${this.apiUrl}super-admin/get-profile`, { headers })
      .pipe(catchError(this.errorHandler));
  }

  updateEmpPassword(newPwd): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Channel: 'WEB_ADMIN',
      Authorization: localStorage.getItem('hks_admin_token')
    });
    return this.http
      .put<any>(`${this.apiUrl}super-admin/update-current-pwd`, newPwd, {
        headers
      })
      .pipe(catchError(this.errorHandler));
  }

  /*
   *------------------------------------------------------------
   */
  addDashboardToDos(toDo): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Channel: 'WEB_ADMIN',
      Authorization: localStorage.getItem('hks_admin_token')
    });
    return this.http
      .post<any>(`${this.apiUrl}super-admin/add-dashboard-todo`, toDo, {
        headers
      })
      .pipe(catchError(this.errorHandler));
  }

  getDashboardToDo(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Channel: 'WEB_ADMIN',
      Authorization: localStorage.getItem('hks_admin_token')
    });
    return this.http
      .get<any>(`${this.apiUrl}super-admin/get-dashboard-todo`, { headers })
      .pipe(catchError(this.errorHandler));
  }
  deleteDashboardToDo(ids): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Channel: 'WEB_ADMIN',
      Authorization: localStorage.getItem('hks_admin_token')
    });
    return this.http
      .delete<any>(`${this.apiUrl}super-admin/delete-dashboard-todo/${ids}`, {
        headers
      })
      .pipe(catchError(this.errorHandler));
  }

  /*
   *------------------------------------------------------------
   */
  private errorHandler(err: HttpErrorResponse) {
    let errmsg = '';
    if (err.status === 401 || err.status === 403) {
      localStorage.removeItem('hks_admin_token');
      localStorage.removeItem('hks_admin_data');
      window.location.href = '/';
    } else if (err.status === 400) {
      errmsg = err.error.message;
    } else {
      errmsg = `Server return ${err.status}, ${err.statusText}`;
    }
    return throwError(errmsg || 'Somthing Went Wrong...');
  }
}
