import { Component, OnInit } from '@angular/core';
import decode from 'jwt-decode';
declare let $: any;

@Component({
  selector: 'app-app-sidebar',
  templateUrl: './app-sidebar.component.html',
  styleUrls: ['./app-sidebar.component.css'],
})
export class AppSidebarComponent implements OnInit {
  scrollTop: any;
  public mainRolesArray = [];
  public subRolesArray = [];
  constructor() {
    const token = localStorage.getItem('hks_admin_token');
    if (token) {
      const tokenPayload = decode(token);
      this.mainRolesArray = tokenPayload.samain_roles;
      this.subRolesArray = tokenPayload.sasub_roles;
    } else {
      this.mainRolesArray = [];
      this.subRolesArray = [];
    }
  }

  ngOnInit() {
    $(document).ready(() => {
      $('#sidebarToggle, #sidebarToggleTop').on('click', (e) => {
        $('body').toggleClass('sidebar-toggled');
        $('.sidebar').toggleClass('toggled');
        if ($('.sidebar').hasClass('toggled')) {
          $('.sidebar .collapse').collapse('hide');
        }
      });

      $(window).resize(() => {
        if ($(window).width() < 768) {
          $('.sidebar .collapse').collapse('hide');
        }
      });

      $('body.fixed-nav .sidebar').on(
        'mousewheel DOMMouseScroll wheel',
        (e) => {
          if ($(window).width() > 768) {
            // tslint:disable-next-line: one-variable-per-declaration
            const e0 = e.originalEvent,
              delta = e0.wheelDelta || -e0.detail;
            this.scrollTop += (delta < 0 ? 1 : -1) * 30;
            e.preventDefault();
          }
        }
      );

      $(document).on('scroll', function () {
        const scrollDistance = $(this).scrollTop();
        if (scrollDistance > 100) {
          $('.scroll-to-top').fadeIn();
        } else {
          $('.scroll-to-top').fadeOut();
        }
      });
    });
  }

  chkMainRolesSideBar(rid) {
    return this.mainRolesArray.indexOf(rid) > -1;
  }
  chkSubRolesSideBar(rid) {
    return this.subRolesArray.indexOf(rid) > -1;
  }
}
