<div class="bg-gradient-primary" style="height: 100vh;">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-xl-10 col-lg-12 col-md-9 mt-5 mb-5">
        <div class="card o-hidden border-0 shadow-lg my-5">
          <div class="card-body p-0">
            <div class="row">
              <div
                class="col-lg-6 d-none d-lg-block bg-login-image"
                style="
                  background-image: url('./assets/img/photo-1518020382113.jpg');
                "
              ></div>
              <div class="col-lg-6">
                <div class="p-5">
                  <div class="text-center">
                    <h1 class="h4 text-gray-900 mb-4">Login!</h1>
                    <div [innerHTML]="signInMsg" class="text-center"></div>
                  </div>
                  <form
                    class="user"
                    [formGroup]="signInForm"
                    (ngSubmit)="userSigninSubmit()"
                  >
                    <div class="form-group">
                      <input
                        type="email"
                        class="form-control form-control-user"
                        placeholder="Enter Username"
                        formControlName="spuser_email"
                        autocomplete="off"
                      />
                    </div>
                    <div class="form-group">
                      <input
                        type="password"
                        class="form-control form-control-user"
                        placeholder="Enter Password"
                        formControlName="spuser_password"
                      />
                    </div>
                    <div class="form-group">
                      <div class="custom-control custom-checkbox small">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="customCheck"
                        />
                        <label class="custom-control-label" for="customCheck"
                          >Remember Me</label
                        >
                      </div>
                    </div>
                    <button
                      type="submit"
                      class="btn btn-primary btn-user btn-block"
                      [disabled]="!signInForm.valid || isBusyBtn"
                    >
                      Login
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
