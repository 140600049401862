<div class="container-fluid">
  <div class="card shadow mb-4">
    <div class="card-header py-3">
      <h6 class="m-0 font-weight-bold text-primary">User profile</h6>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <img class="img-fluid" src="assets/img/1079-1900x300.jpg" />
        </div>
        <div class="col-md-6 mt-3">
          <h5 class="mb-3 mt-3">{{ userAuthService.getUserName() }}</h5>
          <div class="row" *ngIf="(profileData | json) != '{}'">
            <div class="col-md-12 mt-2">
              <table class="table table-striped">
                <tbody>
                  <tr>
                    <td>Emp Email:</td>
                    <td>{{ profileData.saemail }}</td>
                  </tr>
                  <tr>
                    <td>Emp. Mobile:</td>
                    <td>{{ profileData.samobile }}</td>
                  </tr>
                  <tr>
                    <td>Reg Date:</td>
                    <td>{{ profileData.sa_reg_date | date: 'dd/MM/yyyy' }}</td>
                  </tr>
                  <tr>
                    <td>Status:</td>
                    <td>
                      <span
                        *ngIf="profileData.sastatus === '1'"
                        class="badge badge-primary"
                        >Active</span
                      >
                      <span
                        *ngIf="profileData.sastatus === '2'"
                        class="badge badge-success"
                        >In Active</span
                      >
                      <span
                        *ngIf="profileData.sastatus === '3'"
                        class="badge badge-danger"
                        >Blocked</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>Password:</td>
                    <td>
                      <button
                        type="button"
                        class="btn btn-warning btn-sm"
                        (click)="updatePasswordFun()"
                      >
                        Update Password
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-md-6 py-4">
          <br />
          <h4 class="text-primary text-center">Recent Activity</h4>
          <img
            class="mx-auto d-block img-fluid mt-5 mb-5"
            src="assets/img/box.png"
            alt="Img"
          />
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="passwordModal">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Update Password</h4>
        <button type="button" class="close" data-dismiss="modal">
          &times;
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="updatePassword" (ngSubmit)="updatePassSubmit()">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <input
                  type="password"
                  class="form-control"
                  formControlName="current_password"
                  placeholder="Current Password"
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <input
                  type="password"
                  class="form-control"
                  formControlName="new_password"
                  placeholder="New Password (Min 5 chars.)"
                />
              </div>
            </div>
            <div class="col-md-12">
              <button
                type="submit"
                [disabled]="!updatePassword.valid"
                class="btn btn-primary"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
