import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';
import { AppFooterComponent } from './layout/app-footer/app-footer.component';
import { AppHeaderComponent } from './layout/app-header/app-header.component';
import { AppLayoutComponent } from './layout/app-layout/app-layout.component';
import { AppSidebarComponent } from './layout/app-sidebar/app-sidebar.component';
import { LoginComponent } from './login/login.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { UserProfileComponent } from './user-profile/user-profile.component';

import { AuthGuard } from './appservices/auth.guard';
import { RoleGuardGuard } from './appservices/role-guard.guard';

const routes: Routes = [
  { path: '', component: LoginComponent, pathMatch: 'full' },
  {
    path: '',
    component: AppLayoutComponent,
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'user-profile',
        component: UserProfileComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'employee',
        loadChildren: () =>
          import('./employee-section/employee-section.module').then(
            (m) => m.EmployeeSectionModule
          ),
        canActivate: [RoleGuardGuard],
        data: { expectedRole: '1' },
      },
      {
        path: 'site-setting',
        loadChildren: () =>
          import('./settings/settings.module').then((m) => m.SettingsModule),
        canActivate: [RoleGuardGuard],
        data: { expectedRole: '2' },
      },
      {
        path: 'rest-common',
        loadChildren: () =>
          import('./rest-setting/rest-setting.module').then(
            (m) => m.RestSettingModule
          ),
        canActivate: [RoleGuardGuard],
        data: { expectedRole: '3' },
      },
      {
        path: 'store-common',
        loadChildren: () =>
          import('./store-common/store-common.module').then(
            (m) => m.StoreCommonModule
          ),
        canActivate: [RoleGuardGuard],
        data: { expectedRole: '3' },
      },
      {
        path: 'main-category',
        loadChildren: () =>
          import('./main-category/main-category.module').then(
            (m) => m.MainCategoryModule
          ),
        canActivate: [RoleGuardGuard],
        data: { expectedRole: '3' },
      },
      {
        path: 'help-support',
        loadChildren: () =>
          import('./help-support/help-support.module').then(
            (m) => m.HelpSupportModule
          ),
      },
      {
        path: 'banner-section',
        loadChildren: () =>
          import('./banner-section/banner-section.module').then(
            (m) => m.BannerSectionModule
          ),
      },
      {
        path: 'promocode-section',
        loadChildren: () =>
          import('./promocode-section/promocode-section.module').then(
            (m) => m.PromocodeSectionModule
          ),
      },
    ],
  },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

export const routingComponents = [
  LoginComponent,
  DashboardComponent,
  NotFoundComponent,
  UserProfileComponent,
  AppFooterComponent,
  AppHeaderComponent,
  AppLayoutComponent,
  AppSidebarComponent,
];
