<div id="wrapper">
  <app-app-sidebar></app-app-sidebar>
  <div id="content-wrapper" class="d-flex flex-column">
    <div id="content">
      <app-app-header></app-app-header>
      <router-outlet></router-outlet>
    </div>
    <app-app-footer></app-app-footer>
  </div>
</div>
<a
  class="scroll-to-top rounded"
  href="javascript:void(0);"
  (click)="scrollToTop($event)"
>
  <i class="fas fa-angle-up"></i>
</a>
