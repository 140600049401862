<ul
  class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
  id="accordionSidebar"
  style="height: 100%"
>
  <a
    class="sidebar-brand d-flex align-items-center justify-content-center"
    [routerLink]="['/dashboard']"
  >
    <img
      src="../../../assets/img/logo1.png"
      style="width: 150px; height: 100px; margin-left: -30px"
      alt="Example Image"
    />
  </a>

  <hr class="sidebar-divider my-0" />

  <li class="nav-item" [routerLinkActive]="['active']">
    <a class="nav-link" [routerLink]="['/dashboard']">
      <i class="fas fa-fw fa-tachometer-alt"></i>
      <span>Dashboard</span></a
    >
  </li>

  <!-- <hr class="sidebar-divider" />
  <div class="sidebar-heading">
    Employee Section
  </div> -->
  <li
    class="nav-item"
    [routerLinkActive]="['active']"
    *ngIf="chkMainRolesSideBar('1')"
  >
    <a
      class="nav-link collapsed"
      href="#"
      data-toggle="collapse"
      data-target="#collapse1"
      aria-expanded="true"
      aria-controls="collapse1"
    >
      <i class="fas fa-users-cog"></i>
      <span>Employee Section</span>
    </a>
    <div
      id="collapse1"
      class="collapse"
      [routerLinkActive]="['show']"
      aria-labelledby="headingTwo"
      data-parent="#accordionSidebar"
    >
      <div class="bg-white py-2 collapse-inner rounded">
        <a
          *ngIf="chkSubRolesSideBar('1.1')"
          class="collapse-item"
          [routerLinkActive]="['active']"
          [routerLink]="['/', 'employee', 'super-admin']"
          >Super Admin</a
        >
        <a
          *ngIf="chkSubRolesSideBar('1.2')"
          class="collapse-item"
          [routerLinkActive]="['active']"
          [routerLink]="['/', 'employee', 'admin-employee']"
          >Admin Employee Data</a
        >
        <a
          *ngIf="chkSubRolesSideBar('1.3')"
          class="collapse-item"
          [routerLinkActive]="['active']"
          [routerLink]="['/', 'employee', 'marketing-employee']"
          >Marketing Employee</a
        >
        <a
          *ngIf="chkSubRolesSideBar('1.4')"
          class="collapse-item"
          [routerLinkActive]="['active']"
          [routerLink]="['/', 'employee', 'order-employee']"
          >Admin Employee Order</a
        >
        <a
          *ngIf="chkSubRolesSideBar('1.5')"
          class="collapse-item"
          [routerLinkActive]="['active']"
          [routerLink]="['/', 'employee', 'admin-employee-log']"
          >Employee Logs</a
        >
        <a
          *ngIf="chkSubRolesSideBar('1.6')"
          class="collapse-item"
          [routerLinkActive]="['active']"
          [routerLink]="['/', 'employee', 'marketing-employee-log']"
          >Marketing Logs</a
        >
      </div>
    </div>
  </li>

  <hr class="sidebar-divider" />
  <div class="sidebar-heading">Setting Section</div>

  <li
    class="nav-item"
    [routerLinkActive]="['active']"
    *ngIf="chkMainRolesSideBar('2')"
  >
    <a
      class="nav-link collapsed"
      href="#"
      data-toggle="collapse"
      data-target="#collapse2"
      aria-expanded="true"
      aria-controls="collapse2"
    >
      <i class="fas fa-cogs"></i>
      <span>Site Setting</span>
    </a>
    <div
      id="collapse2"
      class="collapse"
      [routerLinkActive]="['show']"
      aria-labelledby="headingTwo"
      data-parent="#accordionSidebar"
    >
      <div class="bg-white py-2 collapse-inner rounded">
        <a
          *ngIf="chkSubRolesSideBar('2.1')"
          class="collapse-item"
          [routerLinkActive]="['active']"
          [routerLink]="['/', 'site-setting', 'country-setting']"
          >Country Setting</a
        >
        <a
          *ngIf="chkSubRolesSideBar('2.2')"
          class="collapse-item"
          [routerLinkActive]="['active']"
          [routerLink]="['/', 'site-setting', 'city-setting']"
          >City Setting</a
        >
        <a
          *ngIf="chkSubRolesSideBar('2.3')"
          class="collapse-item"
          [routerLinkActive]="['active']"
          [routerLink]="['/', 'site-setting', 'zone-setting']"
          >Zone Setting</a
        >
        <a
          *ngIf="chkSubRolesSideBar('2.4')"
          class="collapse-item"
          [routerLinkActive]="['active']"
          [routerLink]="['/', 'site-setting', 'common-setting']"
          >Common Setting</a
        >
        <a
          *ngIf="chkSubRolesSideBar('2.2')"
          class="collapse-item"
          [routerLinkActive]="['active']"
          [routerLink]="['/', 'site-setting', 'services']"
          >Services Setting</a
        >
      </div>
    </div>
  </li>

  <li class="nav-item" [routerLinkActive]="['active']">
    <a
      class="nav-link collapsed"
      href="#"
      data-toggle="collapse"
      data-target="#collapse5"
      aria-expanded="true"
      aria-controls="collapse5"
    >
      <i class="fas fa-photo-video"></i>
      <span>Banner Section</span>
    </a>
    <div
      id="collapse5"
      class="collapse"
      [routerLinkActive]="['show']"
      aria-labelledby="headingTwo"
      data-parent="#accordionSidebar"
    >
      <div class="bg-white py-2 collapse-inner rounded">
        <a
          class="collapse-item"
          [routerLinkActive]="['active']"
          [routerLink]="['/', 'banner-section', 'landing-banner1']"
          >First Landing Banner</a
        >
        <a
          class="collapse-item"
          [routerLinkActive]="['active']"
          [routerLink]="['/', 'banner-section', 'landing-banner2']"
          >Second Landing Banner</a
        >
        <a
          class="collapse-item"
          [routerLinkActive]="['active']"
          [routerLink]="['/', 'banner-section', 'landing-bottom-banner']"
          >Landing Bottom Banner</a
        >
        <a
          class="collapse-item"
          [routerLinkActive]="['active']"
          [routerLink]="['/', 'banner-section', 'covid-banner']"
          >Covid Banner</a
        >
        <a
          class="collapse-item"
          [routerLinkActive]="['active']"
          [routerLink]="['/', 'banner-section', 'pip-videos']"
          >Pip Videos</a
        >
      </div>
    </div>
  </li>

  <li class="nav-item" [routerLinkActive]="['active']">
    <a
      class="nav-link collapsed"
      href="#"
      data-toggle="collapse"
      data-target="#collapse7"
      aria-expanded="true"
      aria-controls="collapse7"
    >
      <i class="fas fa-photo-video"></i>
      <span>Promocode Section</span>
    </a>
    <div
      id="collapse7"
      class="collapse"
      [routerLinkActive]="['show']"
      aria-labelledby="headingTwo"
      data-parent="#accordionSidebar"
    >
      <div class="bg-white py-2 collapse-inner rounded">
        <a
          class="collapse-item"
          [routerLinkActive]="['active']"
          [routerLink]="['/', 'promocode-section', 'rest-promocode']"
          >Restaurant Promocode</a
        >
        <a
          class="collapse-item"
          [routerLinkActive]="['active']"
          [routerLink]="['/', 'promocode-section', 'store-promocode']"
          >Store Promocode</a
        >
      </div>
    </div>
  </li>

  <li class="nav-item" [routerLinkActive]="['active']">
    <a
      class="nav-link collapsed"
      href="#"
      data-toggle="collapse"
      data-target="#collapse4"
      aria-expanded="true"
      aria-controls="collapse4"
    >
      <i class="fas fa-headset"></i>
      <span>Support Data</span>
    </a>
    <div
      id="collapse4"
      class="collapse"
      [routerLinkActive]="['show']"
      aria-labelledby="headingTwo"
      data-parent="#accordionSidebar"
    >
      <div class="bg-white py-2 collapse-inner rounded">
        <a
          class="collapse-item"
          [routerLinkActive]="['active']"
          [routerLink]="['/', 'help-support', 'main-cat']"
          >Support Main Category</a
        >
        <a
          class="collapse-item"
          [routerLinkActive]="['active']"
          [routerLink]="['/', 'help-support', 'data']"
          >Support Data</a
        >
      </div>
    </div>
  </li>

  <hr class="sidebar-divider" />
  <div class="sidebar-heading">Common Data</div>
  <li
    class="nav-item"
    [routerLinkActive]="['active']"
    *ngIf="chkMainRolesSideBar('3')"
  >
    <a
      class="nav-link collapsed"
      href="#"
      data-toggle="collapse"
      data-target="#collapse3"
      aria-expanded="true"
      aria-controls="collapse3"
    >
      <i class="fas fa-database"></i>
      <span>Restaurant Common</span>
    </a>
    <div
      id="collapse3"
      class="collapse"
      [routerLinkActive]="['show']"
      aria-labelledby="headingTwo"
      data-parent="#accordionSidebar"
    >
      <div class="bg-white py-2 collapse-inner rounded">
        <a
          *ngIf="chkSubRolesSideBar('3.1')"
          class="collapse-item"
          [routerLinkActive]="['active']"
          [routerLink]="['/', 'rest-common', 'rest-category']"
          >Rest Category</a
        >
        <a
          *ngIf="chkSubRolesSideBar('3.2')"
          class="collapse-item"
          [routerLinkActive]="['active']"
          [routerLink]="['/', 'rest-common', 'rest-filters']"
          >Rest Filters</a
        >
        <a
          *ngIf="chkSubRolesSideBar('3.2')"
          class="collapse-item"
          [routerLinkActive]="['active']"
          [routerLink]="['/', 'rest-common', 'rest-list-filters']"
          >Rest Listing Filters</a
        >
        <a
          *ngIf="chkSubRolesSideBar('3.3')"
          class="collapse-item"
          [routerLinkActive]="['active']"
          [routerLink]="['/', 'rest-common', 'rest-cuisines']"
          >Rest Cuisines</a
        >
        <a
          *ngIf="chkSubRolesSideBar('3.4')"
          class="collapse-item"
          [routerLinkActive]="['active']"
          [routerLink]="['/', 'rest-common', 'rest-tags']"
          >Rest Tags</a
        >
        <a
          *ngIf="chkSubRolesSideBar('3.5')"
          class="collapse-item"
          [routerLinkActive]="['active']"
          [routerLink]="['/', 'rest-common', 'rest-offer-banner']"
          >Rest Offer Banner</a
        >
      </div>
    </div>
  </li>

  <li
    class="nav-item"
    [routerLinkActive]="['active']"
    *ngIf="chkMainRolesSideBar('3')"
  >
    <a
      class="nav-link collapsed"
      href="#"
      data-toggle="collapse"
      data-target="#collapse9"
      aria-expanded="true"
      aria-controls="collapse9"
    >
      <i class="fas fa-database"></i>
      <span>Main Category</span>
    </a>
    <div
      id="collapse9"
      class="collapse"
      [routerLinkActive]="['show']"
      aria-labelledby="headingTwo"
      data-parent="#accordionSidebar"
    >
      <div class="bg-white py-2 collapse-inner rounded">
        <a
          *ngIf="chkSubRolesSideBar('3.1')"
          class="collapse-item"
          [routerLinkActive]="['active']"
          [routerLink]="['/', 'main-category', 'business-category']"
          >Business Category</a
        >
      </div>
    </div>
  </li>

  <li
    class="nav-item"
    [routerLinkActive]="['active']"
    *ngIf="chkMainRolesSideBar('3')"
  >
    <a
      class="nav-link collapsed"
      href="#"
      data-toggle="collapse"
      data-target="#collapse8"
      aria-expanded="true"
      aria-controls="collapse8"
    >
      <i class="fas fa-database"></i>
      <span>Store Common</span>
    </a>
    <div
      id="collapse8"
      class="collapse"
      [routerLinkActive]="['show']"
      aria-labelledby="headingTwo"
      data-parent="#accordionSidebar"
    >
      <div class="bg-white py-2 collapse-inner rounded">
        <a
          *ngIf="chkSubRolesSideBar('3.1')"
          class="collapse-item"
          [routerLinkActive]="['active']"
          [routerLink]="['/', 'store-common', 'store-banner']"
          >Store Banner</a
        >
        <a
          *ngIf="chkSubRolesSideBar('3.1')"
          class="collapse-item"
          [routerLinkActive]="['active']"
          [routerLink]="['/', 'store-common', 'store-filters']"
          >Store Filters</a
        >
        <a
          *ngIf="chkSubRolesSideBar('3.2')"
          class="collapse-item"
          [routerLinkActive]="['active']"
          [routerLink]="['/', 'store-common', 'store-main-cat']"
          >Store Main Category</a
        >
        <a
          *ngIf="chkSubRolesSideBar('3.2')"
          class="collapse-item"
          [routerLinkActive]="['active']"
          [routerLink]="['/', 'store-common', 'store-sub-cat']"
          >Store Sub Category</a
        >
      </div>
    </div>
  </li>
</ul>
