import { Component, OnInit } from '@angular/core';
import { UserAuthService } from '../appservices/user-auth.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup, Validators } from '@angular/forms';

declare let $: any;
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css'],
})
export class UserProfileComponent implements OnInit {
  public profileData: any = {};
  public updatePassword: FormGroup;
  constructor(
    public userAuthService: UserAuthService,
    private loadingBar: LoadingBarService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.updatePassword = new FormGroup({
      current_password: new FormControl(null, [Validators.required]),
      new_password: new FormControl(null, [
        Validators.required,
        Validators.minLength(5),
      ]),
    });
    this.loadingBar.start();
    this.userAuthService.getEmpProfile().subscribe(
      (res) => {
        if (res.statusCode === 'OK') {
          this.profileData = res.payload.employeeProfile;
        } else {
          this.toastr.warning(res.message);
        }
        this.loadingBar.complete();
      },
      (error) => this.toastr.error(error, 'Error')
    );
  }

  updatePasswordFun() {
    this.updatePassword.reset();
    $('#passwordModal').modal('show');
  }

  public updatePassSubmit() {
    this.loadingBar.start();
    this.userAuthService.updateEmpPassword(this.updatePassword.value).subscribe(
      (res) => {
        if (res.statusCode === 'OK') {
          this.toastr.info(res.message);
          this.updatePassword.reset();
          $('#passwordModal').modal('hide');
        } else {
          this.toastr.warning(res.message);
        }
        this.loadingBar.complete();
      },
      (error) => this.toastr.error(error, 'Error')
    );
  }
}
